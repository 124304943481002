<template>
    <div>
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-show="!isSpinner">
            <!-- My Profile -->
            <b-card title="My Profile">
                <b-row v-if="userData">
                    <b-col md="2">
                        <img v-if="userData.imageUrl&&userData.imageUrl!==''" :src="userData.imageUrl" alt="profile image" width="160px" height="145px" style="object-fit:cover;border-radius:10%" class="profile_img">
                        <img v-else src="@/assets/images/avatars/defaultUser.jpg" alt="default-profile" width="160px" height="145px" style="object-fit:cover;border-radius:10%" class="profile_img">
                    </b-col>
                    <b-col>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Employee ID">
                                    <span class="output_span">{{userData.employeeId}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Name">
                                    <span class="output_span">{{userData.name}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Personal Email">
                                    <span class="output_span">{{userData.personalEmail}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="company Email">
                                    <span class="output_span">{{userData.companyEmail}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Phone No.">
                                <span class="output_span">{{`${userData.countryPhoneCode ? userData.countryPhoneCode.dialCode : '+91'} ` +( (userData.countryPhoneCode ? formatPhoneNumber(userData.personalPhoneNo,userData.countryPhoneCode.mask) : formatPhoneNumber(userData.personalPhoneNo,'99999 99999')))}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Emergency Phone No.">
                                <span class="output_span">{{`${userData.countryPhoneCodeEmergency ? userData.countryPhoneCodeEmergency.dialCode : '+91'} ` +(userData.countryPhoneCodeEmergency ? formatPhoneNumber(userData.emergencyPhoneNo,userData.countryPhoneCodeEmergency.mask) : formatPhoneNumber(userData.emergencyPhoneNo,'99999 99999'))}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Date of Birth" v-if="userData.dob">
                                    <span class="output_span">{{userData.dob.split("-")[2]}} {{months[userData.dob.split("-")[1]-1]}}, {{userData.dob.split("-")[0]}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Gender">
                                    <span class="output_span">{{userData.gender}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Joining Date" v-if="userData.dateOfJoining">
                                    <span class="output_span">{{userData.dateOfJoining.split("-")[2]}} {{months[userData.dateOfJoining.split("-")[1]-1]}}, {{userData.dateOfJoining.split("-")[0]}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Employee Role" v-if="userData.employeeRole">
                                    <span class="output_span">{{userData.employeeRole?userData.employeeRole.name:'--'}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Aadhar Card No.">
                                    <span class="output_span">{{userData.aadharCardNo}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="No. of Students Assignee">
                                   <span class="output_span"> {{userData.noOfStudentAssignee}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" v-if="userData.representative">
                                <b-form-group label="Representative">
                                   <span class="output_span"> {{userData.representative.name}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" v-if="userData.representative">
                                <b-form-group label="Representative Mobile No.">
                                   <span class="output_span"> {{userData.representative.phoneNo}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Address" v-if="userData.country && userData.state && userData.city">
                                    <span class="output_span">{{`${userData.addressLine1}, ${userData.addressLine2!=''?userData.addressLine2+', ':''}${userData.city.name}, ${userData.state.name} - ${userData.pincode}`}}</span>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card>

            <div v-if="userData && userData.employeeDocs">
                <b-card v-if="userData.employeeDocs.length!=0">
                    <!-- Table Top -->
                    <b-row>
                        <b-col
                            cols
                            md="6"            
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                            style="margin:0px 0px 1.5rem 0px !important;"
                        >
                            <div>
                                <span style="font-weight: 600;font-size:18px">
                                    Employee Documents
                                </span>
                            </div>
                        </b-col>

                        <b-col
                            md="6"
                            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                            style="margin:0px 0px 1.5rem 0px !important;"
                        >
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                            />
                        <!-- <label>entries</label> -->
                        </b-col>

                        <b-table
                            striped
                            hover
                            responsive
                            show-empty
                            empty-text="No matching records found"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="userData.employeeDocs"
                            :fields="fields"       
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            class="company_document_table position-relative padding-custom"
                        >
                            <template #cell(id)="data">
                                {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                            </template>
                            <template #cell(document_name)="data">
                                <div>
                                    {{data.item.document_name}}
                                </div>
                            </template>

                            <template #cell(imageName)="data">
                                {{getNameWithoutId(data.item.imageName)}}
                            </template>

                            <template #cell(updatedAt)="data">
                                <span v-if="data.item.updatedAt">
                                    {{new Date(data.item.updatedAt.seconds * 1000).getDate()||new Date(data.item.updatedAt).getDate()}} {{months[new Date(data.item.updatedAt.seconds * 1000).getMonth()||new Date(data.item.updatedAt).getMonth()]}}, {{new Date(data.item.updatedAt.seconds * 1000).getFullYear()||new Date(data.item.updatedAt).getFullYear()}}
                                </span>
                                <span v-else>
                                    --
                                </span>
                            </template>
                            
                            <template #cell(action)="data">
                                <!-- {{data}} -->
                                <div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                                    <img src='@/assets/images/erflog/Download.png' @click.prevent="downloadFile([data.item])" class="action_icon"/>
                                </div>
                                <div v-else>
                                --
                                </div>
                            </template>
                        </b-table>

                        <b-col class="d-flex align-items-center">
                            <span class="text-muted">Showing {{ 1 }} to {{ perPage }} of {{ userData.employeeDocs.length }} entries</span>
                        </b-col>
                        <b-col>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="userData.employeeDocs.length"
                                :per-page="perPage"
                                align="right"
                                first-number
                                last-number
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mb-0 custom_pagination"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </b-card>
            </div>

            <!-- Assigned Students -->
            <b-card v-if="userData">
                <AssignedStudentsList/>
            </b-card>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput,BFormGroup,BForm,BRow,BCol,BButton,BTabs,BTab,
  BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,BSpinner,
  BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,numeric,min,alpha_spaces } from 'vee-validate/dist/rules';

extend('required',required)
extend('max',max)
extend('min',min)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)

import { dbCollections } from "@/utils/firebaseCollection.js";
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'

import AssignedStudentsList from "./assignedStudentsList.vue";
import { getCollectionDataTypesense } from '@/utils/Typesense/queries';
import formatPhoneNumber from '@/utils/phoneNumberFormat.js';
// import firebase from "firebase";
const db = firebase.firestore()

export default {
    components: {
        AssignedStudentsList,
        ValidationProvider,
        ValidationObserver,
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BTabs,
        BTab,
        BSpinner,
        BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
        vSelect
    },
    data() {
        return {
            docId:JSON.parse(localStorage.getItem('userData')).id,
            userData:{},
            months:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            isSpinner:false,

            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                // {
                //     key: 'id', label: 'No.',
                // },
                // {
                //   key: 'docExtension', label: 'Icon',
                // },
                {
                    key: 'document_name', label: 'Document Name',sortable: true
                },
                {
                    key: 'imageName', label: 'File Name',sortable: true
                }, 
                {
                    key: 'updatedAt', label: 'Date Added',sortable: true
                }, 
                {
                    key: 'action', label: 'Action',
                },
            ],
        }
    },
    created() {
        var self = this
        if ('Notification' in window) {
            if (Notification.permission === 'denied' || Notification.permission == 'default') {
                self.$root.$emit('showToastMessage','To receive notifications, please allow Notification permission in site settings and reload page again.','warning')
            } else {
                Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                    console.info('Notification permission has been granted.');
                    } else {
                    console.info('Notification permission has been denied.');
                    }
                })
                .catch(error => {
                    console.error('Error occurred while requesting notification permission:', error);
                });
            }
        } else {
            self.$root.$emit('showToastMessage','To receive notifications, please allow Notification permission in site settings and reload page again.','warning')
        }
        self.isSpinner = true
        db
            .collection(dbCollections.USERS)
            .doc(self.docId)
            .get()
            .then((querySnapshot)=>{
                let searchParameters = {
                    collectionName: `${dbCollections.USERS}`,
                    search: {
                        q:"*",
                        filter_by: `isDeleted:!=true && role:='student' && representative.id:=${self.docId}`,
                        per_page: 1,
                    }
                }

                getCollectionDataTypesense(searchParameters).then(async(result)=>{
                    if(result.found) {
                        self.userData.noOfStudentAssignee = result.found;
                    } else {
                        self.userData.noOfStudentAssignee = 0;
                    }
                }).catch((error)=>{
                    self.isSpinner = false
                    console.error(error)
                })
                if(querySnapshot.exists)
                {
                    if(!querySnapshot.data().isDeleted)
                    {
                        self.userData=querySnapshot.data()
                        if(querySnapshot.data()?.representative && querySnapshot.data().representative?.id) {
                            db.collection(dbCollections.USERS).doc(querySnapshot.data().representative.id).get().then((datas)=>{
                                self.userData.representative.name = datas.data().name;
                                self.userData.representative.phoneNo = datas.data().personalPhoneNo ? ((datas.data().countryPhoneCode ? datas.data().countryPhoneCode.dialCode : '+91') + ' ' +  (datas.data().countryPhoneCode ? formatPhoneNumber(datas.data().personalPhoneNo,datas.data().countryPhoneCode.mask) : formatPhoneNumber(datas.data().personalPhoneNo,'99999 99999'))) : '--';
                            }).catch((error)=>{
                                console.error(error)
                            });
                        }
                        self.isSpinner = false
                    }
                }
                else
                {
                    self.isSpinner = false
                }
            })
            .catch(error=>{
                self.isSpinner = false
                console.log(error)
            })
    },
    methods: {
        formatPhoneNumber,
        downloadFile(item) {
            // var URL = url.split('?alt')
            var self = this
            self.isLoading = true;
            console.log("item",item)

            item.forEach(data=>{
                var name = self.getNameWithoutId(data.imageName);
                self.$axios({
                    url: data.imageUrl,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
        
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', name);
                    document.body.appendChild(fileLink);
                    self.isLoading = false;
                    fileLink.click();
                })
                .catch(error=>{
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Document can not founded in storage',
                            icon: 'CoffeeIcon',
                            variant: 'danger',                               
                        },
                    }) 
                    self.isLoading = false;
                    console.log(error)
                    return;            
                })
            })
        },

        getNameWithoutId(imageName)
        {
            var self = this

            var ext = imageName.split('.')
                ext = ext[ext.length-1]
                var tmp = imageName.split('_')
                var name = ''
                // console.log("name",tmp)
                // name = name.splice(name.length-1,1)

                tmp.forEach((vals,index)=>{
                if(index==0)
                {
                    name+=vals.trim()
                }
                else if(index!=tmp.length-1)
                {
                    name+='_'+vals.trim()
                }
                })
                name+='.'+ext

            return name;
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        //Check if is valid(image) file
        checkFile(e)
        {
            var files = e.target.files || e.dataTransfer.files;
            this.isImage=true
            if(files.length!=0)
            {
                this.previewImage=[]
                this.imageFile=[]
            }
            // this.attachmentImages=[]
            // this.attachments=[]
            files.forEach(data=>{
                if(data.type.split('/')[0]!='image')
                {
                    this.isImage=false
                    // this.attachmentImages=[]
                }
            })
            if(this.isImage){
                this.isImage=true
                this.createImage(files)
                // console.log(this.attachments)
            }
            else
            { 
                document.getElementById("imagePicker").value=""
            }
        },
        createImage(files) {
            //   var image = new Image();
            
            files.forEach(data=>{
                var vm = this;
                var reader = new FileReader();
    
                reader.onload = (e) => {
                    vm.previewImage.push(e.target.result);
                    vm.imageFile.push(data)
                    console.log("images")
                    console.log(vm.previewImage)
                    console.log(vm.imageFile)
                }
                reader.readAsDataURL(data);
            })
        },
        addNewRow(){
            //add field
            this.fieldOfInterests.push({
                field:null,
                course:null
            })
        },
        removeRow(index)
        {
            //remove field
            this.fieldOfInterests.splice(index, 1)
        },
        saveInquiryData() {
            var self = this
            console.log("Validate",self.$refs.personalInfoForm)
            self.$refs.personalInfoForm.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // console.log("Image Data",self.imageFile.name,self.imageFile)
                    // console.log("DAta",self.imageFile,self.name,self.intake,self.yearOfPassing,self.email,self.mobileNumber,self.date,self.gender,self.addressLine1,self.addressLine2,self.country,self.state,self.city,self.pincode,self.collegeName,self.branch,self.cgpa_percentage,self.yearOfPassing,self.overall,self.germanLanguage,self.fieldOfInterests)
                    // self.$router.push({name:'student-inquiry'})
                    if(self.imageFile.length!=0)
                    {
                        uploadImageFile(self.folderName,self.imageFile.name,self.imageFile,res=>{
                            db
                                .collection(dbCollections.INQUIRY)
                                .add({
                                    'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                    'imageUrl':res.imageUrl,
                                    'imageName':res.imageName,
                                    'name':self.name,
                                    'intake':self.intake,
                                    'yearOfPassing':self.yearOfPassing,
                                    'email':self.email,
                                    'mobileNumber':self.mobileNumber,
                                    'dob':self.dob,
                                    'gender':self.gender,
                                    'addressLine1':self.addressLine1,
                                    'addressLine2':self.addressLine2,
                                    'country':self.country,
                                    'state':self.state,
                                    'city':self.city,
                                    'pincode':self.pincode,
                                    'collegeName':self.collegeName,
                                    'branch':self.branch,
                                    'cgpa_percentage':self.cgpa_percentage,
                                    'yearOfPassing':self.yearOfPassing,
                                    'overall':self.overall,
                                    'germanLanguage':self.germanLanguage,
                                    'fieldOfInterests':self.fieldOfInterests,
                                    'isDeleted':false,
                                    'id':""
                                })
                                .then((docRef)=>{
                                    db
                                        .collection(dbCollections.INQUIRY)
                                        .doc(docRef.id)
                                        .update({
                                            id:docRef.id,
                                            updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                                        })
                                        .then(() =>{
                                            self.$router.push({name:'student-inquiry'})
                                            self.$root.$emit('stopSpinner')
                                        })
                                        .catch(error=>{
                                            self.$root.$emit('stopSpinner')
                                            console.log(error)
                                        })
                                })
                                .catch(error=>{
                                    self.$root.$emit('stopSpinner')
                                    console.log(error)
                                })
                        })
                    }
                    else
                    {
                        db
                            .collection(dbCollections.INQUIRY)
                            .add({
                                'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                'imageUrl':'',
                                'imageName':'',
                                'name':self.name,
                                'intake':self.intake,
                                'yearOfPassing':self.yearOfPassing,
                                'email':self.email,
                                'mobileNumber':self.mobileNumber,
                                'dob':self.dob,
                                'gender':self.gender,
                                'addressLine1':self.addressLine1,
                                'addressLine2':self.addressLine2,
                                'country':self.country,
                                'state':self.state,
                                'city':self.city,
                                'pincode':self.pincode,
                                'collegeName':self.collegeName,
                                'branch':self.branch,
                                'cgpa_percentage':self.cgpa_percentage,
                                'yearOfPassing':self.yearOfPassing,
                                'overall':self.overall,
                                'germanLanguage':self.germanLanguage,
                                'fieldOfInterests':self.fieldOfInterests,
                                'isDeleted':false,
                                'id':""
                            })
                            .then((docRef)=>{
                                db
                                    .collection(dbCollections.INQUIRY)
                                    .doc(docRef.id)
                                    .update({
                                        id:docRef.id,
                                        updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                                    })
                                    .then(() =>{
                                        self.$router.push({name:'student-inquiry'})
                                        self.$root.$emit('stopSpinner')
                                    })
                                    .catch(error=>{
                                        self.$root.$emit('stopSpinner')
                                        console.log(error)
                                    })
                            })
                            .catch(error=>{
                                self.$root.$emit('stopSpinner')
                                console.log(error)
                            })
                    }
                }
                else
                {
                    console.log("Success",success)
                    self.$root.$emit('stopSpinner')
                }
            })
        },
    },
}
</script>
<style scoped>
.bottom-border{
    border-bottom: 1px solid #cfcfcf;
}
.card-bottom-border{
    border-bottom: 1px solid #cfcfcf;
    border-radius: unset;
    margin-top: 15px;
    padding-bottom: 15px;
}
.card-title-border-bottom{
    border-top:1px solid #cfcfcf;
    padding-top:1.5rem;
}
</style>