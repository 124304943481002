<template>

  <div>

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="status"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2 mlr-0">

        <!-- Table Top -->
        <b-row>

          <!-- Search -->
          <b-col
            cols
            md="6"            
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4 class="table_title mb-0">Assigned Students</h4>
          </b-col>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <div style="margin-right: 15px">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="handleSearch"
              />
            </div>
            <label>Filter by Status</label>
            <b-form-select
                v-model="status"
                :options="statusOptions"
                placeholder="Select"
                class="per-page-selector d-inline-block mx-50"
            />
            
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>entries</label> -->
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative p-0_table padding-custom"
        :items="students"
        responsive
        :fields="tableColumns"
        :current-page="1"
        primary-key="id"
        show-empty
        hover
        @row-clicked="handleRowClick"
        no-local-sorting
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="handleSortChange"
      >
        <!-- Column: No. -->
        <template #cell(no)="data">
          <div>
              <span>{{(currentPage-1) * perPage + data.index + 1}}</span>
          </div>
        </template>

        <!-- Column: Phone no -->
        <template #cell(phone_no)="data">
          <div style="white-space: nowrap">
            {{data.item.phone_no}}
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="'light-'+data.value.variant"
          >
            <div style="white-space: nowrap">
              {{data.value.status}}
            </div>
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              @change="handlePageChange"
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormSelect,BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useAssignedStudentsList'

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'
const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormSelect,
    BFormGroup,
    vSelect,
  },
  data(){
    return{
      selectedUser:"",
      university:null,
      representative:null,
      statusOptions:[
        { value: null, text: 'All' },
        { value: 'Application Pending', text: 'Application Pending' },
        { value: 'Application Inprogress', text: 'Application Inprogress' },
        { value: 'Application Submitted', text: 'Application Submitted' },
        { value: 'Admission Successful', text: 'Admission Successful' },
        { value: 'Application Process Hold', text: 'Application Process Hold' }
      ],
    }
  },
  created() {
    this.employeeId = JSON.parse(localStorage.getItem('userData')).id
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      employeeId,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      status,
      statusFilter,
      handlePageChange,
      handleSortChange,
      handleSearch,
      students
    } = useUsersList()  

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      employeeId,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      status,
      statusFilter,
      handlePageChange,
      handleSortChange,
      handleSearch,
      students
    }
  },
  methods:{
    handleRowClick(data)
    {
      // console.log(data)
      //Show User Details
      this.$router.push({name:'student-information',params:{name:data.student_name,id:data.id}})
    },
    createAccount(e)
    {
      var index = (this.currentPage-1) * this.perPage + this.selectedUser.index + 1
      console.log("createAccount",this.selectedUser,index)
      this.selectedUser=''
    },
    closeInquiry(e)
    {
      var index = (this.currentPage-1) * this.perPage + this.selectedUser.index + 1
      console.log("closeInquiry",this.selectedUser,index)
      db
        .collection(dbCollections.INQUIRY)
        .doc(this.selectedUser.item.id)
        .delete()
        .then(()=>{
          console.log("Inquiry closed successfully.")
          this.selectedUser=''
        })
        .catch(error=>{
          console.log(error)
        })
    }
  }
}
</script>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */
.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.position-relative.p-0_table.table-responsive {
    padding: 0px!important;
}
.m-2.mlr-0 {
    margin:0px 0px 1.5rem!important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
